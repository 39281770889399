<template>
  <v-card class="app-tab">
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">
              Tag
            </th>
            <th class="text-center">
              Status
            </th>
            <th class="text-center">
              Preview
            </th>
            <th class="text-center">
              Ações
            </th>
            <th class="text-center">
              Nova imagem
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in images" :key="item.tag">
            <td class="text-center">
              {{ item.tag }}
            </td>
            <td class="text-center">
              <v-layout>
                <v-icon class="mr-2" x-small :color="item.url ? 'success' : 'error'">mdi-brightness-1</v-icon
                >{{ item.url ? 'Imagem configurada' : 'Sem imagem' }}
              </v-layout>
            </td>
            <td class="text-center">
              <v-flex>
                <v-layout justify-center align-center>
                  <img v-if="item.url" :src="item.url" width="60" class="pa-2 ma-2" />
                  <v-alert v-else class="my-2" dense type="error" outlined>Sem imagem</v-alert>
                </v-layout>
              </v-flex>
            </td>
            <td class="text-center">
              <v-flex>
                <v-layout justify-center align-center>
                  <v-btn color="primary" :disabled="!item.exists" small @click="openImage(item)">Ver Imagem</v-btn>
                  <v-btn color="success" small @click="addImageModal(item)">Adicionar/Alterar Imagem</v-btn>
                  <v-btn
                    v-if="item.tag === 'MASTER_ICON_NAVBAR' || (item.tag === 'AUTH_SCREEN_BACKGROUND_IMAGE' && item.exists)"
                    color="error"
                    small
                    @click="removeImage(item.tag)"
                    >Remover</v-btn
                  >
                </v-layout>
              </v-flex>
            </td>
            <td class="text-center">
              <img v-if="imagesToUpload.length" :src="getImage(item.tag)" width="60" class="pa-2 ma-2" />
              <img v-else :src="require('@/assets/empty-image.png')" width="60" class="pa-2 ma-2" />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <v-btn class="action-btn mt-4" color="primary" width="150" :disabled="imagesToUpload.length < 1 && imagesToDelete.length < 1" @click="save">
            <span v-if="!uploadImageLoading">SALVAR</span>
            <v-progress-circular v-else indeterminate color="#fff"></v-progress-circular>
          </v-btn>
        </tfoot>
      </template>
    </v-simple-table>

    <image-cropper
      :dialog="addImageDialog"
      :images-to-upload.sync="imagesToUpload"
      :image="selectedImage"
      :isResizableCrop="isResizableCrop"
      :resizeDirection="resizeDirection"
      @close="closeCropper"
    />
  </v-card>
</template>

<script>
import { MUTATION_UPLOAD_ASSETS, MUTATION_UPDATE_APP_PROPS } from '@/modules/accounts/graphql'
import emptyImage from '@/assets/empty-image.png'

export default {
  components: {
    ImageCropper: () => import('@/components/images/ImageCropper.vue')
  },
  props: {
    app: {
      type: Object,
      default: () => {}
    },
    accountId: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    panel: false,
    addImageDialog: false,
    images: [],
    selectedImage: {},
    imagesToUpload: [],
    imagesToDelete: [],
    uploadImageLoading: false,
    isResizableCrop: false,
    resizeDirection: 'none'
  }),
  mounted() {
    this.setValues(this.app)
  },
  methods: {
    setValues(app) {
      this.images.push(
        { tag: 'ICON_IOS', width: 1024, height: 1024, type: 'jpg', url: app?.assets?.icon_ios || null, exists: !!app?.assets?.icon_ios },
        { tag: 'ICON_ANDROID', width: 1024, height: 1024, type: 'jpg', url: app?.assets?.icon_android || null, exists: !!app?.assets?.icon_android },
        {
          tag: 'ICON_STORE_ANDROID',
          width: 512,
          height: 512,
          type: 'png',
          url: app?.assets?.icon_store_android || null,
          exists: !!app?.assets?.icon_store_android
        },
        {
          tag: 'BANNER_STORE_ANDROID',
          width: 1024,
          height: 500,
          type: 'png',
          url: app?.assets?.banner_store_android || null,
          exists: !!app?.assets?.banner_store_android
        },
        { tag: 'ICON_NAVBAR', width: 300, height: 84, type: 'png', url: app?.assets?.icon_navbar || null, exists: !!app?.assets?.icon_navbar },
        {
          tag: 'MASTER_ICON_NAVBAR',
          width: 300,
          height: 84,
          type: 'png',
          url: app?.assets?.master_icon_navbar || null,
          exists: !!app?.assets?.master_icon_navbar
        },
        { tag: 'SPLASH', width: 512, height: 512, type: 'png', url: app?.assets?.splash || null, exists: !!app?.assets?.splash },
        {
          tag: 'ROUNDED_LOGO_IMAGE',
          width: 100,
          height: 100,
          type: 'png',
          url: app?.assets?.rounded_logo_image || null,
          exists: !!app?.assets?.rounded_logo_image
        },
        {
          tag: 'FULL_SCREEN_SPLASH',
          width: 1080,
          height: 1920,
          type: 'jpg',
          url: app?.assets?.full_screen_splash || null,
          exists: !!app?.assets?.full_screen_splash
        },
        {
          tag: 'AUTH_SCREEN_BACKGROUND_IMAGE',
          width: 1080,
          height: 1920,
          type: 'jpg',
          url: app?.colors?.auth_screen_config?.background_image || null,
          exists: !!app?.colors?.auth_screen_config?.background_image
        }
      )
    },
    openImage(image) {
      return window.open(image.url, '_blank')
    },
    closeImage() {
      this.selectedImage = {}
      this.imageDialog = false
    },
    addImageModal(image) {
      if (image.tag === 'ICON_NAVBAR') {
        this.isResizableCrop = image.tag === 'ICON_NAVBAR'
        this.resizeDirection = 'height'
      } else {
        this.isResizableCrop = false
        this.resizeDirection = 'none'
      }

      this.selectedImage = {
        label: image.tag,
        width: image.width,
        height: image.height,
        type: image.type
      }
      this.addImageDialog = true
    },
    closeCropper() {
      this.selectedImage = {}
      this.addImageDialog = false
    },
    getImage(tag) {
      const item = this.imagesToUpload.find(item => item.tag === tag)
      if (item) return item.imagePreview
      else return emptyImage
    },
    removeImage(imageTag) {
      const parsedImages = this.images.reduce((acc, image) => {
        if (image.tag === imageTag) {
          return [...acc, { ...image, url: null, exists: false }]
        }

        return [...acc, image]
      }, [])

      this.images = parsedImages
      this.imagesToDelete = [...this.imagesToDelete, imageTag]
    },
    async save() {
      this.selectedImage = {}
      this.uploadImageLoading = true

      const payloadFiles = {}

      for (const img of this.imagesToUpload) {
        payloadFiles[img.tag.toLocaleLowerCase()] = img.file
      }

      const uploadAssetsVariables = {
        uploadInput: {
          account_id: this.accountId,
          files: payloadFiles
        }
      }

      const deletedAssets = this.imagesToDelete.reduce((acc, item) => {
        return { ...acc, [item.toLocaleLowerCase()]: '' }
      }, {})

      const filterAuthScreenBackground = this.imagesToDelete.filter(item => item === 'AUTH_SCREEN_BACKGROUND_IMAGE')

      let update = {
        channels: {
          app: {
            build: {
              assets: deletedAssets
            }
          }
        }
      }

      if (filterAuthScreenBackground.length > 0) {
        delete deletedAssets['auth_screen_background_image']
        update = {
          channels: {
            app: {
              build: {
                assets: deletedAssets,
                colors: {
                  auth_screen_config: {
                    background_image: ''
                  }
                }
              }
            }
          }
        }
      }

      const updateAppPropsVariables = {
        account_id: this.accountId,
        update
      }

      try {
        const res = await this.$apollo.mutate({
          mutation: MUTATION_UPLOAD_ASSETS,
          variables: uploadAssetsVariables,
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['app_config']
          }
        })

        if (this.imagesToDelete.length > 0) {
          await this.$apollo.mutate({
            mutation: MUTATION_UPDATE_APP_PROPS,
            variables: updateAppPropsVariables,
            context: {
              headers: {
                authorization: localStorage.getItem('session_id')
              },
              uri: this.$microservicesUrls['app_config']
            }
          })
        }

        res?.data?.uploadAssets?.forEach(e => {
          console.log('Link: ' + e?.message)
        })
        this.$snackbar({ message: 'Upload realizado com sucesso', snackbarColor: '#2E7D32' })
        this.$emit('refresh')
      } catch (err) {
        console.log(err?.message)
        this.$snackbar({ message: 'Falha ao realizar upload da imagem', snackbarColor: '#F44336' })
      } finally {
        this.imagesToUpload = []
        this.imagesToDelete = []
        this.uploadImageLoading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.app-tab {
  margin-top: 20px;
  padding: 15px;
}

.v-btn {
  margin-right: 8px;
}
</style>
